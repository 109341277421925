<div class="wrapper" mat-dialog-content>
    <div class="popup">
        <div class="icon-wrapper">
            <span class="icon-unavailable"></span>
        </div>
        <div class="popup-content">
            <span class="popup-text">
                {{ 'tsd_campaign_limit_exceeded' | localize | async }}
            </span>
        </div>
        <button class="popup-button" (click)="dismiss()">
            {{ 'tsd_overlaycompontent_ok' | localize | async }}
        </button>
    </div>
</div>
