import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdjustFontSizeDirective } from './directives/adjust-font-size.directive';

import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ModesoCoreModule } from '@modeso/modeso-lib-core-fe';
import { ModesoLocalizationModule } from '@modeso/modeso-lib-localization-fe';
import { CampaignButtonComponent } from './campaign-button/campaign-button.component';
import { CampaignOrderBasicInformationComponent } from './campaign-order-basic-information/campaign-order-basic-information.component';
import { CampaignOrderTotalComponent } from './campaign-order-total/campaign-order-total.component';
import { CampaignVariationListComponent } from './campaign-variation-list/campaign-variation-list.component';
import { CouponLimitComponent } from './coupon-limit/coupon-limit.component';
import { DiscountOfferImageGridComponent } from './discount-offer-image-grid/discount-offer-image-grid.component';
import { InformationAccordionComponent } from './information-accordion/information-accordion.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { CurrencyNumberPipe } from './pipes/currency-number.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SwissNumberPipe } from './pipes/swiss-number.pipe';
import { TsdInsufficientQuantityComponent } from './tsd-insufficient-quantity/tsd-insufficient-quantity.component';
import { TsdReservationPopupComponent } from './tsd-reservation-popup/tsd-reservation-popup.component';
import { TsdCampaignLimitExceededPopupComponent } from './tsd-campaign-limit-exceeded-popup/tsd-campaign-limit-exceeded-popup';
import { TsdUserAgeCheckComponent } from './tsd-user-age-check/tsd-user-age-check.component';

export const COMPONENTS = [
    // components
    PageHeaderComponent,
    InformationAccordionComponent,
    LoadingSpinnerComponent,
    DiscountOfferImageGridComponent,
    CampaignButtonComponent,
    CampaignOrderTotalComponent,
    CampaignOrderBasicInformationComponent,
    CampaignVariationListComponent,
    TsdUserAgeCheckComponent,
    TsdReservationPopupComponent,
    TsdInsufficientQuantityComponent,
    CouponLimitComponent,
    TsdCampaignLimitExceededPopupComponent,

    // pipes
    SwissNumberPipe,
    SafeHtmlPipe,
    MarkdownPipe,
    CurrencyNumberPipe,

    // directives
    AdjustFontSizeDirective,
];

export const ANGULAR_MATERIAL = [
    MatProgressBarModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatDialogModule,
];

@NgModule({
    imports: [...ANGULAR_MATERIAL, ModesoCoreModule, CommonModule, ModesoLocalizationModule],
    exports: [...COMPONENTS, ...ANGULAR_MATERIAL, ModesoCoreModule, ModesoLocalizationModule],
    declarations: [...COMPONENTS],
    providers: [SafeHtmlPipe],
})
export class SharedModule {}
