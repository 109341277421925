<div class="carousel-container" [ngClass]="{ 'zoomed-in': !normalMode }">
    <div #carouselElement>
        <div
            *ngFor="let slide of campaignImageSlider; let i = index"
            class="carousel-cell"
            [ngClass]="{
                'zoomed-in': !normalMode,
                'remove-margins': campaignImageSlider.length === 1,
                'add-margins':
                    campaignImageSlider.length > 2 &&
                    activeIndex !== 0 &&
                    activeIndex !== campaignImageSlider.length - 1,
            }"
            (click)="navigateToZoomPage(slide.type)"
        >
            <!-- Pill -->
            <div *ngIf="pillText" class="image-pill">{{ pillText }}</div>

            <!-- Arrows -->
            <div class="arrow" *ngIf="campaignImageSlider.length > 0 && !isAnyZoomedIn">
                <div class="action leftArrow">
                    <img
                        *ngIf="activeIndex !== 0"
                        (click)="goToPreviousSlide(); $event.stopPropagation()"
                        src="assets/chevron-right.svg"
                        alt="left arrow"
                        [ngClass]="{ 'zoomed-in': !normalMode }"
                    />
                </div>
                <div class="action rightArrow">
                    <img
                        *ngIf="activeIndex < campaignImageSlider.length - 1"
                        (click)="goToNextSlide(); $event.stopPropagation()"
                        src="assets/chevron-right.svg"
                        alt="right arrow"
                        [ngClass]="{ 'zoomed-in': !normalMode }"
                    />
                </div>
            </div>

            <!-- Image slide -->
            <img
                *ngIf="normalMode && (slide.type === visualTypes.IMAGE || slide.type === visualTypes.NO_BG_IMAGE)"
                fetchpriority="high"
                class="image-slide"
                [ngClass]="{
                    'image-slide-with-no-background': slide.type === visualTypes.NO_BG_IMAGE,

                    'zoomed-in': !normalMode,
                }"
                [src]="slide.mediaUrl"
                alt="slide"
                (click)="clickImg(slide.mediaUrl); $event.stopPropagation()"
            />

            <pinch-zoom
                #pinchZoom
                backgroundColor="#FFFFFF"
                [limitPan]="true"
                [limit-zoom]="5"
                [minScale]="1"
                [disableZoomControl]="'disable'"
                *ngIf="!normalMode && (slide.type === visualTypes.IMAGE || slide.type === visualTypes.NO_BG_IMAGE)"
            >
                <img
                    fetchpriority="high"
                    class="image-slide"
                    [ngClass]="{
                        'image-slide-with-no-background': slide.type === visualTypes.NO_BG_IMAGE,
                        'zoomed-in': !normalMode,
                    }"
                    [src]="slide.mediaUrl"
                    alt="slide"
                    (click)="clickImg(slide.mediaUrl); $event.stopPropagation()"
                />
            </pinch-zoom>

            <!-- Youtube player -->
            <app-youtube-player
                [ngClass]="{ 'zoomed-in': !normalMode }"
                fetchpriority="high"
                *ngIf="slide.type === visualTypes.VIDEO && slide.videoId != null"
                [videoId]="slide.videoId"
            />
        </div>
    </div>
</div>
