/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Optional, Pipe, type PipeTransform } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { DOMPURIFY_CONFIG, MODESO_DEFAULT_DOMPURIFY_CONFIG } from './dompurify.config';

@Pipe({ name: 'safeHTML' })
export class SafeHtmlPipe implements PipeTransform {
    protected config: { RETURN_DOM_FRAGMENT?: false | undefined; RETURN_DOM?: false | undefined };

    constructor(
        protected sanitizer: DomSanitizer,
        @Optional()
        @Inject(DOMPURIFY_CONFIG)
        config: { RETURN_DOM_FRAGMENT?: false | undefined; RETURN_DOM?: false | undefined },
    ) {
        if (config !== undefined) {
            this.config = config;
        } else {
            this.config = MODESO_DEFAULT_DOMPURIFY_CONFIG;
        }
    }

    public transform(value: string): SafeHtml {
        const sanitizedContent = DOMPurify.sanitize(value, this.config);
        return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    }
}
