<div class="discount-offer">
    <p class="discount-chip">-{{ discount }}{{ discountUnit }}</p>
    <div class="logo" [ngStyle]="{ 'background-color': circularBubbleColorHexCode }">
        <img [src]="logoUrl" alt="logo" />
    </div>

    <img
        class="discount-offer-image"
        [ngClass]="{
            'discount-offer-image-with-no-background': imageType === imageTypes.NO_BG_IMAGE,
        }"
        src="{{ imageUrl }}"
        alt="discount-offer-image"
    />
</div>
