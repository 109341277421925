<div class="order-item">
    <div class="image-container">
        <img
            class="order-image"
            [ngClass]="{
                'order-image-with-no-background': !isPlaceHolder && item.imageType === imageTypes.NO_BG_IMAGE,
                'order-image-placeHolder': isPlaceHolder,
            }"
            [src]="item.imgUrl"
            [alt]="item.product"
            (error)="loadPlaceholderImage()"
        />
    </div>
    <div class="details-container">
        <div class="product-info">
            <h2 class="brand-name">{{ item.brand }}</h2>
            <p class="product-name">{{ item.product }}</p>
        </div>
        <div class="date-price-info">
            <p class="order-date">{{ item.date | date: 'dd.MM.yyyy' : '' : 'de-CH' }}</p>
            <p class="price">{{ CURRENCY }} {{ item.price | currencyNumber }}</p>
        </div>
    </div>
</div>
