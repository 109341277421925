import { Injectable } from '@angular/core';
import type { ICountdownPillConfiguration } from '@modeso/types__tsd-lib-products-be';
import { Purpose, VisualTypes, type ICategoryDTO, type SpecialDealDTO } from '@modeso/types__tsd-lib-products-be';
import { differenceInHours, differenceInSeconds } from 'date-fns';
import { compact, groupBy, orderBy, uniqBy } from 'lodash-es';
import { MAIN_PRODUCT_INDEX, ZERO_LENGTH } from '../../../../domain/constants/constants';
import type { CampaignListItem } from '../../../../domain/models/campaign-list-item.interface';
import type { CategoriesWithCampaigns } from '../../../../domain/models/categories-with-campaigns.interface';
import type { Category } from '../../../../domain/models/category.interface';
import { ImagePillType } from '../../../../domain/models/enums/image-pill-type.enum';
import { StockAvailabilityMode } from '../../../../domain/models/enums/stock-availability-mode.enum';
import type { ImagePill } from '../../../../domain/models/image-pill.interface';
import type { Language } from '../../../../domain/models/types/language.type';
import { SpecialDealUtils } from '../../../../domain/utils/specialdeals.utils';
import isCorrectResolution from '../../../../domain/utils/visuals.utils';

const EMPTY_STOCK = 0;
const GREEN_STOCK_THRESHOLD = 80;
const YELLOW_STOCK_THRESHOLD = 30;
const GRAY_STOCK_THRESHOLD = 0;
const TWO_DAYS_IN_HOURS = 48;
const ONE_DAY_IN_HOURS = 24;
const ZERO_HOUR = 0;

@Injectable({ providedIn: 'root' })
export class CampaignMapper {
    public mapSpecialDealDTOToCategoriesWithCampaign(
        campaigns: SpecialDealDTO[],
        language: Language,
    ): CategoriesWithCampaigns[] {
        const categoriesWitCampaigns: CategoriesWithCampaigns[] = [];
        const campaignsWithCategories = campaigns.filter(
            (campaign) => campaign.category != null && campaign.categorySortingOrder != null,
        );

        if (campaignsWithCategories.length === ZERO_LENGTH) {
            return categoriesWitCampaigns;
        }

        const categoriesDto = campaignsWithCategories.map((campaign) => campaign.category);
        const categoriesDtoWithRemovedUndefinedElements = compact<ICategoryDTO>(categoriesDto);
        const categories = this.mapCategoriesDtoToCategories(categoriesDtoWithRemovedUndefinedElements, language);

        const uniqueUnOrderedCategories = uniqBy<Category>(categories, 'id');
        const sortedCategories = orderBy<Category>(uniqueUnOrderedCategories, ['order', 'title'], 'asc');

        const groupedCampaignsByCategoryOrderNumber = groupBy<SpecialDealDTO>(campaignsWithCategories, 'category._id');

        for (const key in groupedCampaignsByCategoryOrderNumber) {
            const unsortedCampaigns = groupedCampaignsByCategoryOrderNumber[key];
            const sortedCampaigns = orderBy<SpecialDealDTO>(
                unsortedCampaigns,
                ['categorySortingOrder', `productData[${MAIN_PRODUCT_INDEX}].title`],
                'asc',
            );
            groupedCampaignsByCategoryOrderNumber[key] = sortedCampaigns;
        }

        for (const category of sortedCategories) {
            const sortedCampaignsOfCategory = groupedCampaignsByCategoryOrderNumber[`${category.id}`];

            if (sortedCampaignsOfCategory == undefined) {
                throw new Error('Campaigns of category are not exist');
            }

            const categoryWithCampaign: CategoriesWithCampaigns = {
                title: category.title,
                iconUrl: category.iconUrl,
                campaigns: this.mapCampaignsDtoToCampaignListItems(sortedCampaignsOfCategory, language),
            };

            categoriesWitCampaigns.push(categoryWithCampaign);
        }

        return categoriesWitCampaigns;
    }

    public mapCampaignsDtoToCampaignListItems(campaignsDto: SpecialDealDTO[], language: Language): CampaignListItem[] {
        const campaignListItems: CampaignListItem[] = [];

        for (const campaignDto of campaignsDto) {
            const id = campaignDto.internalCampaignId;
            const stockAvailabilityPercentageValue = SpecialDealUtils.calculateRemainingStock(campaignDto);

            const discount = SpecialDealUtils.calculateDiscount(campaignDto);

            const priceAfterDiscount = SpecialDealUtils.findDealPrice(campaignDto).dealPriceWithStock;

            const configuration = SpecialDealUtils.findDealPrice(campaignDto).config;

            const originalPrice = SpecialDealUtils.findOriginalPrice(campaignDto, configuration);

            const campaignBrandName = campaignDto.productData[MAIN_PRODUCT_INDEX].brand;

            const campaignName = campaignDto.name;

            const productName = campaignDto.productData[MAIN_PRODUCT_INDEX].title;

            const isOutOfStock = stockAvailabilityPercentageValue === EMPTY_STOCK;

            const stockProgressAvailabilityMode = this.getStockAvailabilityMode(stockAvailabilityPercentageValue);

            const hideAvailabilityBar: boolean = campaignDto.hideAvailabilityBar;

            const imageInformation = campaignDto.productData[MAIN_PRODUCT_INDEX].visuals.find(
                (image) => image.purpose === Purpose.THUMBNAIL && isCorrectResolution(image),
            );

            if (imageInformation == null) {
                throw new Error('Image of campaign is undefined, could not find a visual of type thumbnail');
            }

            if (imageInformation.type !== VisualTypes.IMAGE && imageInformation.type !== VisualTypes.NO_BG_IMAGE) {
                throw new Error('The image must either of type Image or type Image with no background');
            }

            let imagePill: ImagePill | undefined;
            if (campaignDto.countDownPillConfiguration != null) {
                const campaignEndDate = new Date(campaignDto.endDateTime);
                imagePill = this.getImagePill(
                    campaignDto.countDownPillConfiguration,
                    campaignEndDate,
                    language,
                    isOutOfStock,
                );
            }

            const campaignListItem: CampaignListItem = {
                id,
                stockAvailabilityPercentageValue,
                discountPercentageValue: Number(discount),
                priceAfterDiscount,
                originalPrice,
                campaignBrandName,
                campaignName,
                productName,
                isOutOfStock,
                stockProgressAvailabilityMode,
                imageUrl: imageInformation.url,
                imageType: imageInformation.type,
                imagePill,
                hideAvailabilityBar,
            };

            campaignListItems.push(campaignListItem);
        }

        return campaignListItems;
    }

    private getStockAvailabilityMode(stockAvailabilityPercentageValue: number): StockAvailabilityMode {
        if (stockAvailabilityPercentageValue >= GREEN_STOCK_THRESHOLD) {
            return StockAvailabilityMode.HIGH;
        } else if (
            stockAvailabilityPercentageValue < GREEN_STOCK_THRESHOLD &&
            stockAvailabilityPercentageValue >= YELLOW_STOCK_THRESHOLD
        ) {
            return StockAvailabilityMode.MEDIUM;
        } else if (
            stockAvailabilityPercentageValue > GRAY_STOCK_THRESHOLD &&
            stockAvailabilityPercentageValue < YELLOW_STOCK_THRESHOLD
        ) {
            return StockAvailabilityMode.LOW;
        } else {
            return StockAvailabilityMode.EMPTY;
        }
    }

    private getImagePill(
        pillConfiguration: ICountdownPillConfiguration,
        endDate: Date,
        language: Language,
        isSoldOut: boolean,
    ): ImagePill | undefined {
        if (isSoldOut && pillConfiguration.soldOut != null && pillConfiguration.soldOut[language] != null) {
            return { type: ImagePillType.TEXT, text: pillConfiguration.soldOut[language] };
        } else if (isSoldOut && (pillConfiguration.soldOut == null || pillConfiguration.soldOut[language] == null)) {
            return undefined;
        }

        const nowDate = new Date();

        const differenceBetweenEndDateAndCurrentDateInHours = differenceInHours(endDate, nowDate);
        if (
            differenceBetweenEndDateAndCurrentDateInHours >= TWO_DAYS_IN_HOURS &&
            pillConfiguration.moreThan2Days != null &&
            pillConfiguration.moreThan2Days[language] != null
        ) {
            const trimmedMoreTha2Days = pillConfiguration.moreThan2Days[language]?.trim();

            if (trimmedMoreTha2Days == null || trimmedMoreTha2Days.length === ZERO_LENGTH) {
                return undefined;
            }
            return { type: ImagePillType.TEXT, text: pillConfiguration.moreThan2Days[language]?.trim() };
        } else if (
            differenceBetweenEndDateAndCurrentDateInHours < TWO_DAYS_IN_HOURS &&
            differenceBetweenEndDateAndCurrentDateInHours >= ONE_DAY_IN_HOURS &&
            pillConfiguration.moreThan1Day != null &&
            pillConfiguration.moreThan1Day[language] != null
        ) {
            const trimmedMoreThan1Day = pillConfiguration.moreThan1Day[language]?.trim();

            if (trimmedMoreThan1Day == null || trimmedMoreThan1Day.length === ZERO_LENGTH) {
                return undefined;
            }
            return { type: ImagePillType.TEXT, text: pillConfiguration.moreThan1Day[language]?.trim() };
        } else if (
            differenceBetweenEndDateAndCurrentDateInHours < ONE_DAY_IN_HOURS &&
            differenceBetweenEndDateAndCurrentDateInHours > ZERO_HOUR
        ) {
            return {
                type: ImagePillType.COUNT_DOWN,
                counterInSeconds: differenceInSeconds(endDate, nowDate),
            };
        }

        return undefined;
    }

    private mapCategoriesDtoToCategories(categoriesDto: ICategoryDTO[], language: Language): Category[] {
        const categories: Category[] = [];

        for (const categoryDto of categoriesDto) {
            const id = categoryDto._id;

            if (id == null) {
                throw new Error('The id of the category is null or undefined');
            }

            const category: Category = {
                id,
                title: categoryDto.text[language],
                order: categoryDto.orderNumber,
                iconUrl: categoryDto.icon,
            };

            categories.push(category);
        }

        return categories;
    }
}
