/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { AfterViewInit, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import type { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web';

export interface MenuItem {
    action: () => void;
    iconLink: string;
    textLink: string;
    fontColor: string;
}
@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [],
})
export class MenuComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Output() menuIsOpen = new EventEmitter<boolean>();
    @Input() isSuperCashbackVisible: boolean = true;
    @Input() isUpcomingDealVisible: boolean = true;

    @ViewChild('lottieContainer', { static: true }) lottieContainer!: ElementRef<HTMLDivElement>;

    menuArray: MenuItem[] = [];
    isMenuOpen = false;

    anim!: AnimationItem;

    isFirstTimeOpened = false;

    private readonly closeToMenu = 'assets/animation/close-to-menu_V3.json';
    private readonly menuToClose = 'assets/animation/menu-to-close_V2.json';

    constructor(
        public router: Router,
        private readonly renderer: Renderer2,
    ) {}

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
        this.menuIsOpen.emit(this.isMenuOpen);
        this.changeAnimationPath();
    }

    close(): void {
        this.isMenuOpen = false;
        this.menuIsOpen.emit(this.isMenuOpen);
        this.changeAnimationPath();
    }

    public goToUrl(key: string): void {
        void this.router.navigate([this.router.url.concat(`/${key}`)]);
    }

    private handleDataDynamic() {
        this.menuArray = [
            {
                action: () => {
                    this.goToUrl('myprofile');
                },
                textLink: 'tsd_my_account_profile',
                iconLink: 'assets/profile.svg',
                fontColor: '',
            },
            {
                action: () => {
                    this.goToUrl('myorders');
                },
                textLink: 'tsd_my_account_orders',
                iconLink: 'assets/my-orders-icon.svg',
                fontColor: '',
            },
            {
                action: () => {
                    this.goToUrl('newsletter');
                },
                textLink: 'tsd_subscribe_newsletter',
                iconLink: 'assets/envelope-line-icon.svg',
                fontColor: '',
            },
            {
                action: () => {
                    this.goToUrl('contact');
                },
                textLink: 'tsd_my_account_contact',
                iconLink: 'assets/speaking-bubbles-line-icon.svg',
                fontColor: '',
            },
            {
                action: () => {
                    this.goToUrl('faq');
                },
                textLink: 'tsd_my_account_faq',
                iconLink: 'assets/faqs-icon.svg',
                fontColor: '',
            },
        ];

        if (this.isUpcomingDealVisible ?? false) {
            this.menuArray.push({
                action: () => {
                    this.goToUrl('upcomingdeals');
                },
                textLink: 'tsd_upcoming_deal',
                iconLink: 'assets/ebene.svg',
                fontColor: '',
            });
        }

        if (this.isSuperCashbackVisible ?? false) {
            this.menuArray.push({
                action: () => {
                    this.goToUrl('supercashback');
                },
                textLink: 'tsd_super_cashback',
                iconLink: 'assets/Super_Cashback_Gradient_icon.svg',
                fontColor: '#e95069',
            });
        }
        return this.menuArray;
    }

    private changeAnimationPath(): void {
        this.anim?.destroy();
        this.anim = lottie.loadAnimation({
            container: this.lottieContainer?.nativeElement,
            renderer: 'svg',
            loop: false,
            autoplay: this.isFirstTimeOpened,
            path:
                !this.isFirstTimeOpened ? this.menuToClose
                : !this.isMenuOpen ? this.closeToMenu
                : this.menuToClose,
        });
        this.isFirstTimeOpened = true;

        // console.log('menuElement', this.menuElement.nativeElement);
        if (this.isMenuOpen) {
            const doc = document.querySelector('.cdk-overlay-pane');
            if (!doc) return;
            setTimeout(() => {
                this.renderer.setStyle(doc, 'top', '68px');
            }, 0);
        }
    }

    ngAfterViewInit(): void {
        this.changeAnimationPath();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.handleDataDynamic();
    }

    ngOnInit(): void {
        this.handleDataDynamic();
    }

    ngOnDestroy(): void {
        this.anim?.destroy();
    }
}
