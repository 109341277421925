<div class="carousel-container">
    <div #carouselElement>
        <div class="carousel-cell" *ngFor="let discountOffer of discountOffersToView; index as i">
            <ng-container
                *ngIf="
                    (this.discountOffers.length > MAX_OFFERS_TO_SHOW && i < MAX_OFFERS_TO_SHOW - 1) ||
                        this.discountOffers.length <= MAX_OFFERS_TO_SHOW;
                    else showMoreTemplate
                "
            >
                <ng-container
                    [ngTemplateOutlet]="discountOfferItem"
                    [ngTemplateOutletContext]="{
                        discount: discountOffer.discount,
                        discountUnit: discountOffer.discountUnit,
                        circularBubbleRgbaColor: discountOffer.circularBubbleRgbaColor,
                        logoUrl: discountOffer.logoUrl,
                        imageUrl: discountOffer.imageUrl,
                        id: discountOffer.id,
                    }"
                ></ng-container>
            </ng-container>
        </div>
        <div class="carousel-cell"></div>
    </div>
</div>

<!-- Show more template -->
<ng-template #showMoreTemplate>
    <div class="discount-offer-deal show-more-box" (click)="onShowMoreClicked()">
        <div class="bubble">
            <span class="show-more-amount">+{{ discountOffers.length - 5 }}</span>
        </div>
    </div>
</ng-template>

<!-- Existing item template -->
<ng-template
    #discountOfferItem
    let-discount="discount"
    let-discountUnit="discountUnit"
    let-circularBubbleRgbaColor="circularBubbleRgbaColor"
    let-logoUrl="logoUrl"
    let-imageUrl="imageUrl"
    let-id="id"
>
    <div class="discount-offer-deal" (click)="onSlideClicked(id)">
        <div class="image-chip">-{{ discount }}{{ discountUnit }}</div>
        <div class="bubble" [ngStyle]="{ 'background-color': circularBubbleRgbaColor }">
            <img class="discount-offer-logo" fetchpriority="high" src="{{ logoUrl }}" alt="logo-url" />
        </div>
        <img class="discount-offer-deal-image" fetchpriority="high" src="{{ imageUrl }}" alt="discount-offer-image" />
    </div>
</ng-template>
