import { Component, Input, type OnInit } from '@angular/core';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { SWISS_CURRENCY } from '../../../../domain/constants/constants';
import { ImagePillType } from '../../../../domain/models/enums/image-pill-type.enum';
import { StockAvailabilityMode } from '../../../../domain/models/enums/stock-availability-mode.enum';
import type { ImagePill } from '../../../../domain/models/image-pill.interface';

const ZERO_DEFAULT_VALUE = 0;

@Component({
    selector: 'app-campaign-list-item',
    templateUrl: './campaign-list-item.component.html',
    styleUrls: ['./campaign-list-item.component.scss'],
})
export class CampaignListItemComponent implements OnInit {
    @Input({ required: true }) imageUrl: string | undefined;
    @Input({ required: true })
    imageType!: VisualTypes.IMAGE | VisualTypes.NO_BG_IMAGE;
    @Input() hideAvailabilityBar: boolean | undefined = false;
    @Input() imagePill: ImagePill | undefined;
    @Input({ required: true }) stockAvailabilityPercentageValue: number = ZERO_DEFAULT_VALUE;
    @Input({ required: true }) stockProgressAvailabilityMode: StockAvailabilityMode = StockAvailabilityMode.LOW;
    @Input({ required: true }) campaignName: string | undefined;
    @Input({ required: true }) productName: string | undefined;
    @Input({ required: true }) discountPercentageValue: number = ZERO_DEFAULT_VALUE;
    discountValueText: string = '';
    @Input({ required: true }) originalPrice: number = ZERO_DEFAULT_VALUE;
    @Input({ required: true }) priceAfterDiscount: number = ZERO_DEFAULT_VALUE;
    @Input({ required: true }) isOutOfStock: boolean = false;

    protected readonly stockAvailabilityMode = StockAvailabilityMode;
    protected readonly imagePillType = ImagePillType;
    protected readonly currency = SWISS_CURRENCY;
    protected readonly imageTypes = VisualTypes;

    ngOnInit(): void {
        if (isNaN(this.discountPercentageValue) || this.priceAfterDiscount === ZERO_DEFAULT_VALUE) {
            this.discountValueText = 'tsd_free';
        } else {
            this.discountValueText = `-${this.discountPercentageValue}%`;
        }
    }
}
