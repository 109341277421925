<section class="variation-list">
    <div class="group" *ngFor="let group of variationList; let groupIndex = index">
        <!-- title -->
        <p>{{ group.title }}</p>
        <!-- list of group specification -->
        <div class="group-specifications-list" [class.hasTwoColumns]="group.type !== variationType.DEFAULT">
            <div
                class="group-specification"
                [ngClass]="{
                    image: group.type === variationType.IMAGE || group.type === variationType.COLOR,
                    active: spec.selected,
                    disabled: spec.disabled,
                    hasFourColumns: group.data.length > 4,
                }"
                *ngFor="let spec of group.data; let specIndex = index"
                (click)="selectSpecification(group.key, spec.key)"
            >
                <!-- image -->
                <img
                    class="variant-image"
                    [ngClass]="{
                        'variant-image-with-no-background':
                            spec.imageType != null && spec.imageType === imageTypes.NO_BG_IMAGE,
                    }"
                    [src]="spec.key"
                    alt="spec image"
                    *ngIf="group.type === variationType.IMAGE && spec.key"
                />

                <!-- color -->
                <div
                    class="color"
                    *ngIf="group.type === variationType.COLOR"
                    [style.backgroundColor]="'#' + spec.key"
                ></div>

                <!-- text -->
                <span class="variant-text" appAdjustFontSize>{{ spec.label }}</span>
            </div>
        </div>
    </div>
</section>
