<div>
    <div *ngFor="let dealOfTheDay of dealsOfTheDay">
        <div class="deal-of-the-day-title" *ngIf="dealOfTheDay.displayTitle">
            <img src="{{ icon ? icon : 'assets/coupons.svg' }}" alt="coupons-icon" class="deal-of-the-day-title-icon" />
            <span class="deal-of-the-day-title-text" [innerHTML]="dealOfTheDay.title | markdownToSafeHTML"></span>
        </div>
        <div [ngSwitch]="dealOfTheDay.type" class="deal-of-the-day">
            <div *ngSwitchCase="dealOfTheDayTypes.CAMPAIGN">
                <app-campaign-list-item
                    *ngIf="
                        dealOfTheDay.discountPercentageValue != null &&
                        dealOfTheDay.isOutOfStock != null &&
                        dealOfTheDay.stockAvailabilityPercentageValue != null &&
                        dealOfTheDay.originalPrice != null &&
                        dealOfTheDay.priceAfterDiscount != null &&
                        dealOfTheDay.stockProgressAvailabilityMode != null &&
                        dealOfTheDay.imageType != null
                    "
                    (click)="onCampaignClick(dealOfTheDay.id)"
                    [imageUrl]="dealOfTheDay.imageUrl"
                    [imageType]="dealOfTheDay.imageType"
                    [campaignName]="dealOfTheDay.campaignName"
                    [imagePill]="dealOfTheDay.imagePill"
                    [discountPercentageValue]="dealOfTheDay.discountPercentageValue"
                    [isOutOfStock]="dealOfTheDay.isOutOfStock"
                    [stockAvailabilityPercentageValue]="dealOfTheDay.stockAvailabilityPercentageValue"
                    [productName]="dealOfTheDay.productName"
                    [originalPrice]="dealOfTheDay.originalPrice"
                    [priceAfterDiscount]="dealOfTheDay.priceAfterDiscount"
                    [stockProgressAvailabilityMode]="dealOfTheDay.stockProgressAvailabilityMode"
                    [hideAvailabilityBar]="dealOfTheDay.hideAvailabilityBar"
                />
            </div>

            <div *ngSwitchCase="dealOfTheDayTypes.DISCOUNT_OFFER">
                <app-discount-offer-deal-of-the-day
                    (click)="onDiscountOfferClicked(dealOfTheDay.id)"
                    [imageUrl]="dealOfTheDay.imageUrl"
                    [imageType]="dealOfTheDay.imageType"
                    [logoUrl]="dealOfTheDay.logoUrl"
                    [text]="dealOfTheDay.text"
                    [circularBubbleRgbaColor]="dealOfTheDay.circularBubbleRgbaColor"
                />
            </div>

            <div *ngSwitchCase="dealOfTheDayTypes.NEWS" (click)="onNewsClicked(dealOfTheDay.redirectLink)">
                <app-news-deal-of-the-day
                    [imageUrl]="dealOfTheDay.imageUrl"
                    [text]="dealOfTheDay.text"
                    [imageType]="dealOfTheDay.imageType"
                />
            </div>
        </div>
    </div>
</div>
