<div class="basic-info-container">
    <div class="title">{{ 'tsd_order_summary' | localize | async }}</div>

    <div class="items">
        <div class="product" *ngFor="let item of items">
            <div class="column1">
                <img
                    [ngClass]="{
                        'image-with-no-background':
                            item.imageUrl !== placeHolderImage && item.imageType === imageTypes.NO_BG_IMAGE,
                        'order-image-placeHolder': item.imageUrl === placeHolderImage,
                    }"
                    [src]="item?.imageUrl"
                    alt="product-image"
                    (error)="loadPlaceholderImage(item)"
                />
            </div>
            <div class="column2">
                <div class="row1">
                    <div class="brand-name">{{ item?.brandName }}</div>
                    <div class="product-name">{{ item?.productName }}</div>
                    <div class="options">{{ item?.options }}</div>
                </div>
                <div class="row2">
                    <div class="quantity">
                        {{ 'tsd_accessories_quantities' | localize | async }}: {{ item?.quantity }}
                    </div>
                    <div class="price">{{ CURRENCY }} {{ item.price | currencyNumber }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
