<app-loading-spinner *ngIf="isOrderLoading$ | async; else data" style="margin: auto"></app-loading-spinner>

<ng-template #data>
    <!-- page header -->
    <app-page-header
        [title]="'tsd_order_details' | localize | async"
        (backButtonClick)="navigateBack()"
    ></app-page-header>
    <div class="order-summary">
        <!-- order summary -->
        <app-campaign-order-basic-information [items]="items" />

        <div class="divider"></div>

        <!-- shipping address -->
        <div class="delivery">
            <div class="row1">
                <div class="deliver-to">{{ 'tsd_delivery_address' | localize | async }}</div>
            </div>
            <div class="row2">
                <div class="address-name">
                    {{ currentOrder?.shippingAddress?.forename }}
                    {{ currentOrder?.shippingAddress?.lastname }}
                </div>
                <div class="address-street">{{ currentOrder?.shippingAddress?.street }}</div>
                <div class="address-zipcode-city">
                    {{ currentOrder?.shippingAddress?.zipcode }}
                    {{ currentOrder?.shippingAddress?.city }}
                </div>
                <div class="address-email">{{ currentOrder?.email }}</div>
            </div>
        </div>

        <!-- billing address -->
        <div class="delivery invoice-address">
            <div class="row1">
                <div class="deliver-to">{{ 'tsd_billing_address' | localize | async }}</div>
            </div>
            <div class="row2">
                <div class="address-name">
                    {{ currentOrder?.billingAddress?.forename }}
                    {{ currentOrder?.billingAddress?.lastname }}
                </div>
                <div class="address-street">{{ currentOrder?.billingAddress?.street }}</div>
                <div class="address-zipcode-city">
                    {{ currentOrder?.billingAddress?.zipcode }}
                    {{ currentOrder?.billingAddress?.city }}
                </div>
                <div class="address-email">{{ currentOrder?.email }}</div>
            </div>
        </div>

        <div class="divider"></div>

        <div class="delivery order-details">
            <div class="row1">
                <div class="deliver-to">{{ 'tsd_order_details' | localize | async }}</div>
            </div>
            <div class="shipping-status">
                <div class="">
                    <div class="order-details-element">{{ 'tsd_order_date' | localize | async }}</div>
                    <div class="order-details-element">
                        {{ currentOrder?.createdAt | date: 'dd.MM.yyyy' : '' : 'de-CH' }}
                    </div>
                </div>
                <div class="">
                    <div class="order-details-element">{{ 'tsd_order_status' | localize | async }}</div>
                    <div class="order-details-element right">
                        {{
                            currentOrder?.shippingStatus === 'SHIPPED' ?
                                ('tsd_orderdetails_shipped' | localize | async)
                            :   status
                        }}
                    </div>
                </div>
                <ng-container *ngIf="currentOrder?.trackLink">
                    <div class="">
                        <div class="order-details-element">{{ 'tsd_track_number' | localize | async }}</div>
                        <div class="code right" (click)="copyCode(currentOrder?.trackLink?.boxNumber)">
                            <span>{{ currentOrder?.trackLink?.boxNumber }} </span>
                            <img src="assets/copy.svg" alt="" />
                            <!-- toast message -->
                            <div *ngIf="showToast" class="toast-message">{{ 'tsd_copied' | localize | async }}</div>
                        </div>
                    </div>
                    <div class="">
                        <div class="order-details-element">{{ 'tsd_track_order' | localize | async }}</div>
                        <div class="right">
                            <a [href]="currentOrder?.trackLink?.trackLink" target="_blank">
                                {{ 'tsd_track_link' | localize | async }}
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="divider"></div>

        <app-campaign-order-total [orderCosts]="orderCosts" [fromOrderDetails]="true" />
    </div>
</ng-template>
