/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/unbound-method */
import { Location } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FormControl, FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import type {
    ICartOperationResponse,
    ICartOrderLine,
    ICreateOrUpdateCartRequest,
} from '@modeso/types__tsd-lib-cart-be';
import type { SpecialDealDTO } from '@modeso/types__tsd-lib-products-be';
import type { IPersonDto } from '@modeso/types__tsd-lib-user-be';
import { SalutationEnum } from '@modeso/types__tsd-lib-user-be';
import { Store } from '@ngrx/store';
import debug from 'debug';
import fastDeepEqual from 'fast-deep-equal/es6';
import type { PhoneNumber } from 'libphonenumber-js';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import type { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import type { SavePageViewRequest } from '../../../../domain/models/save-page-view-request.interface';
import { OrderPendingEnum } from '../../../../domain/store/actions/action-names.enum';
import {
    AnalyticsActions,
    CampaignActions,
    OrderActions,
    SuccessActions,
    UserProfileActions,
} from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectCampaignByInternalId } from '../../../../domain/store/reducers/campaign.reducer';
import { selectCurrentPurchaseOrder } from '../../../../domain/store/reducers/order.reducer';
import { isUpdateOrderLoading } from '../../../../domain/store/reducers/pending.reducer';
import { selectSuccessKey } from '../../../../domain/store/reducers/success.reducer';
import { selectUserProfile } from '../../../../domain/store/reducers/user-profile.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import { replaceSpacesWithDashes } from '../../../../domain/utils/url-with-name.utils';
import { emailValidator } from '../../../../domain/validators/email-validator';
import type { DropDownKeyValue } from '../my-profile/my-profile.component';

@Component({
    selector: 'app-campaign-shipping',
    templateUrl: './campaign-shipping.component.html',
    styleUrls: ['./campaign-shipping.component.scss'],
})
export class CampaignShippingComponent implements OnInit {
    private readonly fb = inject(FormBuilder);
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly activeRoute = inject(ActivatedRoute);
    private readonly destroyRef = inject(DestroyRef);
    private readonly location = inject(Location);

    private currentPurchaseOrderSubscription: Subscription | undefined;

    private parsePhone: PhoneNumber | undefined;

    private campaign: SpecialDealDTO | undefined;

    formValidityMaxLimit = false;

    isUpdateOrderLoading$ = this.store.select(isUpdateOrderLoading);

    currentPurchaseOrder: ICartOperationResponse | undefined;

    myAccountForm = this.fb.nonNullable.group({
        salutation: [SalutationEnum.MR, Validators.required],
        forename: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        lastname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        correspondanceLanguage: ['', Validators.required],
        deliveryAddress: this.fb.nonNullable.group({
            street: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            zipcode: [
                '',
                [Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern('^[0-9]*$')],
            ],
            city: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        }),
        billingAddress: this.fb.nonNullable.group({
            street: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            zipcode: [
                '',
                [Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern('^[0-9]*$')],
            ],
            city: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        }),
        phone: ['', Validators.required],
        email: ['', [Validators.required, emailValidator()]],
        receiveEmails: [true],
        sameAsDelivery: [true],
        saveSettings: [true],
        acceptTerms: [false, Validators.requiredTrue],
    });

    phoneElement = this.myAccountForm.get('phone') as FormControl;

    private isCreateNewUser = true;
    private readonly language = getLanguage();

    salutations: DropDownKeyValue[] = [
        { value: SalutationEnum.MR, name: 'tsd_salutation_male' },
        { value: SalutationEnum.MRS, name: 'tsd_salutation_female' },
    ];

    languagesArray: DropDownKeyValue[] = [
        { value: 'de-ch', name: 'tsd_languages_german' },
        { value: 'en-us', name: 'tsd_languages_english' },
        { value: 'fr-ch', name: 'tsd_languages_french' },
        { value: 'it-ch', name: 'tsd_languages_italian' },
    ];

    protected defaultSalutation = this.salutations[0];
    protected defaultLanguage =
        this.languagesArray.find((language) => language.value === this.language) ?? this.languagesArray[0];

    private handleAddressToggle(): void {
        this.myAccountForm
            .get('sameAsDelivery')
            ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (val) => this.handleBillingAddressChange(val),
            });
    }

    private handleBillingAddressChange(sameAsDelivery: boolean): void {
        const billingAddress = this.myAccountForm.get('billingAddress');
        if (sameAsDelivery === true) {
            billingAddress?.disable();
            this.myAccountForm.controls.billingAddress.controls.city.setValidators(null);
            this.myAccountForm.controls.billingAddress.controls.zipcode.setValidators(null);
            this.myAccountForm.controls.billingAddress.controls.street.setValidators(null);
        } else {
            billingAddress?.enable();
            this.myAccountForm.controls.billingAddress.controls.street.setValidators([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
            ]);
            this.myAccountForm.controls.billingAddress.controls.zipcode.setValidators([
                Validators.required,
                Validators.maxLength(4),
                Validators.minLength(4),
                Validators.pattern('^[0-9]*$'),
            ]);
            this.myAccountForm.controls.billingAddress.controls.city.setValidators([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
            ]);
        }
        this.myAccountForm.controls.billingAddress.controls.city.updateValueAndValidity();
        this.myAccountForm.controls.billingAddress.controls.zipcode.updateValueAndValidity();
        this.myAccountForm.controls.billingAddress.controls.street.updateValueAndValidity();
        billingAddress?.updateValueAndValidity();
        this.myAccountForm.updateValueAndValidity();
    }

    private checkEqualAddressDetails(profile: IPersonDto): boolean {
        return (
            profile?.billingAddress?.city === profile?.deliveryAddress?.city &&
            profile?.billingAddress?.zipcode === profile?.deliveryAddress?.zipcode &&
            profile?.billingAddress?.street === profile?.deliveryAddress?.street
        );
    }

    onCheckboxClick(controlName: string): void {
        const control = this.myAccountForm.get(controlName);
        if (control) {
            control.setValue(!(control.value as boolean));
            const isSameAsDelivery = control.value as boolean;
            if (controlName !== 'sameAsDelivery') return;
            if (isSameAsDelivery) {
                // If same as delivery, copy values from delivery address to billing address
                const deliveryAddress = this.myAccountForm.get('deliveryAddress');
                const billingAddress = this.myAccountForm.get('billingAddress');

                if (deliveryAddress && billingAddress) {
                    billingAddress.setValue({
                        street: deliveryAddress.get('street')?.value ?? '',
                        zipcode: deliveryAddress.get('zipcode')?.value ?? '',
                        city: deliveryAddress.get('city')?.value ?? '',
                    });

                    billingAddress.markAsDirty();
                    billingAddress.markAsTouched();
                }
            } else {
                // If not same as delivery, clear billing address
                const billingAddress = this.myAccountForm.get('billingAddress');
                if (billingAddress) {
                    billingAddress.reset(); // Resetting the entire form group
                }
            }
        }
    }

    goBack(): void {
        // return this.router.navigate(['../']);
        this.location.back();
    }

    onSubmit(): void {
        if (!this.myAccountForm.valid || !this.currentPurchaseOrder) return;

        const { sameAsDelivery, acceptTerms, saveSettings, ...data } = this.myAccountForm.value as IPersonDto & {
            sameAsDelivery: boolean;
            saveSettings: boolean;
            acceptTerms: boolean;
        };

        if (!acceptTerms) return;

        this.parsePhone = parsePhoneNumber(data.phone, 'CH');
        const userPayload = { ...data, phone: this.parsePhone.number };

        const sharedInfo = {
            forename: userPayload.forename,
            lastname: userPayload.lastname,
            salutation: userPayload.salutation,
        };

        userPayload.deliveryAddress = {
            ...userPayload.deliveryAddress,
            ...sharedInfo,
        };

        if (sameAsDelivery ?? false) {
            userPayload.billingAddress = { ...userPayload.deliveryAddress, ...sharedInfo };
        } else {
            userPayload.billingAddress = { ...userPayload.billingAddress, ...sharedInfo };
        }
        this.parsePhone = parsePhoneNumber(userPayload.phone, 'CH');
        userPayload.phone = this.parsePhone.number;

        if (this.currentPurchaseOrderSubscription != null) {
            this.currentPurchaseOrderSubscription.unsubscribe();
        }
        if (saveSettings) {
            this.store.dispatch(
                this.isCreateNewUser ?
                    UserProfileActions.createUserProfile(userPayload)
                :   UserProfileActions.updateUserProfile(userPayload),
            );
        }

        const orderLine: ICartOrderLine[] = this.currentPurchaseOrder.orderLine.map((salesOrderLine) => ({
            product: salesOrderLine.product.product,
            quantity: salesOrderLine.quantity,
            config: salesOrderLine.config,
        }));

        const orderPayload: ICreateOrUpdateCartRequest = {
            billingAddress: userPayload?.billingAddress,
            shippingAddress: userPayload?.deliveryAddress,
            orderUuid: this.currentPurchaseOrder?.orderUuid,
            campaignId: this.currentPurchaseOrder?.campaignId,
            email: userPayload?.email,
            terminalId: this.currentPurchaseOrder.terminalId,
            phone: userPayload?.phone,
            recieveEmails: userPayload?.receiveEmails,
            language: userPayload?.correspondanceLanguage,
            isAgeCheckConfirmed: true,
            orderLine,
        };
        this.store.dispatch(OrderActions.updateOrder(orderPayload));
    }

    getLink(link: string | null): string {
        if (link == null) return '';
        const merchant = this.currentPurchaseOrder?.orderLine[0]?.product?.merchant;
        return link
            .replace('{twintAgb}', this.getAgbLink())
            .replace('{merchantName}', merchant?.name ?? '')
            .replace('{merchantAGB}', merchant?.termsAndConditionsLink ?? '');
    }

    private getAgbLink(): string {
        return `assets/pdf/AGB_Special_Deals_${this.language.split('-')[0]}.pdf`;
    }

    private loadUserProfile(): void {
        this.store
            .select(selectUserProfile)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (profile) => {
                    if (profile) {
                        this.isCreateNewUser = false;
                        const sameAsDelivery = this.checkEqualAddressDetails(profile);
                        this.parsePhone = parsePhoneNumber(profile.phone, 'CH');
                        const updatedProfile = { ...profile, phone: this.parsePhone.formatInternational() };
                        this.myAccountForm.patchValue({
                            ...updatedProfile,
                            sameAsDelivery,
                            saveSettings: true,
                            receiveEmails: profile.receiveEmails,
                        });
                        this.defaultSalutation =
                            this.salutations.find((salutation) => salutation.value === profile.salutation) ??
                            this.salutations[0];

                        this.defaultLanguage =
                            this.languagesArray.find((language) => language.value === profile.correspondanceLanguage) ??
                            this.languagesArray[0];

                        // parsePhone
                        const phoneNumber = profile.phone;
                        this.checkPhoneNumber(phoneNumber);

                        this.myAccountForm.updateValueAndValidity();
                        this.formValidityMaxLimit = this.checkMaxLengthAndSetFormValidity();
                        this.checkMaxLengthAndMarkInvalidFields();
                    } else {
                        this.handleBillingAddressChange(true);
                        this.myAccountForm.get('correspondanceLanguage')?.setValue(this.language as string);
                    }
                },
            });

        this.myAccountForm.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: (status) => {
                this.formValidityMaxLimit = status === 'VALID';
            },
        });
    }

    private loadCurrentPurchaseOrder(): void {
        this.currentPurchaseOrderSubscription = this.store
            .select(selectCurrentPurchaseOrder)
            .pipe(
                distinctUntilChanged((previousPurchaseOrder, currentPurchaseOrder) =>
                    fastDeepEqual(previousPurchaseOrder, currentPurchaseOrder),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (currentPurchaseOrder) => {
                    if (!currentPurchaseOrder || !(currentPurchaseOrder?.orderUuid ?? '')) {
                        void this.router.navigateByUrl(`${this.language}/error`);
                        return;
                    }
                    this.currentPurchaseOrder = currentPurchaseOrder;

                    // edit current form if currentPurchaseOrder has personal information data
                    if (currentPurchaseOrder?.billingAddress && currentPurchaseOrder?.shippingAddress) {
                        this.onCheckboxClick('acceptTerms');
                    }

                    this.dispatchPageView(currentPurchaseOrder.campaignId);
                },
            });
    }

    private listenToSuccessKey(): void {
        this.store
            .select(selectSuccessKey)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (successKey) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison, @typescript-eslint/strict-boolean-expressions
                    if (!successKey || successKey !== OrderPendingEnum.UPDATE_ORDER) return;

                    this.store.dispatch(SuccessActions.clearSuccessKey());
                    const formattedName = replaceSpacesWithDashes(this.campaign?.name);

                    void this.router.navigate([
                        this.language,
                        'campaign',
                        formattedName,
                        this.currentPurchaseOrder?.campaignId,
                        'ordersummary',
                    ]);
                },
            });
    }

    ngOnInit(): void {
        this.store.dispatch(UserProfileActions.loadUserProfile());

        this.loadCurrentPurchaseOrder();
        this.loadUserProfile();
        this.listenToSuccessKey();
        this.handleAddressToggle();

        const id = this.activeRoute.snapshot.params['id'] as string;
        const campaignName = this.activeRoute.snapshot.params['campaignName'] as string;
        if (!id) return;
        this.store.dispatch(CampaignActions.loadCampaignById(id));
        this.loadCampaign(id);
    }

    private dispatchPageView(campaignId: string): void {
        const pageViewRequest: SavePageViewRequest = {
            productLine: campaignId,
            pageName: AnalyticsPageName.CAMPAIGN_SHIPPING,
            terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
        };
        this.store.dispatch(AnalyticsActions.savePageView({ payload: pageViewRequest }));
    }

    handlePhoneInputChanged(event: Event): void {
        const target = event.target as HTMLInputElement;
        const phoneNumber = target.value;
        this.checkPhoneNumber(phoneNumber);
    }

    checkPhoneNumber(phoneNumber: string): void {
        // We should catch error here because there are error thrown in the console
        try {
            this.parsePhone = parsePhoneNumber(phoneNumber, 'CH');
        } catch (error: any) {
            debug(error.message);
        }
        if (isValidPhoneNumber(phoneNumber, 'CH') === true && this.parsePhone?.country === 'CH') {
            this.myAccountForm.get('phone')?.setErrors(null);
            const formatPhoneNumber = this.parsePhone?.formatInternational();
            this.phone?.setValue(formatPhoneNumber);
        } else {
            this.myAccountForm.get('phone')?.setErrors({ isMsgAppear: true });
            this.myAccountForm.get('phone')?.markAsTouched();
        }
    }

    checkMaxLengthAndMarkInvalidFields() {
        const deliveryAddress = this.myAccountForm.get('deliveryAddress') as FormGroup;
        const billingAddress = this.myAccountForm.get('billingAddress') as FormGroup;
        const forename = this.myAccountForm.get('forename');
        const lastname = this.myAccountForm.get('lastname');
        const deliveryStreet = deliveryAddress.get('street');
        const deliveryCity = deliveryAddress.get('city');
        const billingStreet = billingAddress.get('street');
        const billingCity = billingAddress.get('city');
        const billingForename = billingAddress.get('forename');
        const billingLastname = billingAddress.get('lastname');

        const controls = [
            forename,
            lastname,
            deliveryStreet,
            deliveryCity,
            billingStreet,
            billingCity,
            billingForename,
            billingLastname,
        ];
        for (const control of controls) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unsafe-member-access
            if (control?.value && control.value.length > 50) {
                // control.setValue(control.value.substring(0, 50));
                // set error
                control.setErrors({ maxlength: true });
                control.markAsTouched();
            }
        }
    }

    checkMaxLengthAndSetFormValidity(): boolean {
        const forename = this.myAccountForm.value.forename;
        const lastname = this.myAccountForm.value.lastname;
        const deliveryStreet = this.myAccountForm.value.deliveryAddress?.street;
        const deliveryCity = this.myAccountForm.value.deliveryAddress?.city;
        const billingStreet = this.myAccountForm.value.billingAddress?.street;
        const billingCity = this.myAccountForm.value.billingAddress?.city;
        const billingForename = forename;
        const billingLastname = lastname;

        const fields: (string | undefined)[] = [
            forename,
            lastname,
            deliveryStreet,
            deliveryCity,
            billingStreet,
            billingCity,
            billingForename,
            billingLastname,
        ];
        for (const field of fields) {
            if (field && field.length > 50) {
                return false;
            }
        }
        return true;
    }

    private loadCampaign(id: string): void {
        this.store
            .select(selectCampaignByInternalId(id))
            .pipe(
                filter((campaign) => campaign != null),
                distinctUntilChanged((previousCampaign, currentCampaign) =>
                    fastDeepEqual(previousCampaign, currentCampaign),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (campaign) => {
                    if (campaign) {
                        this.campaign = campaign;
                    }
                },
            });
    }

    get forename() {
        return this.myAccountForm.get('forename');
    }
    get lastname() {
        return this.myAccountForm.get('lastname');
    }
    get deliveryAddress() {
        return this.myAccountForm.get('deliveryAddress');
    }
    get billingAddress() {
        return this.myAccountForm.get('billingAddress');
    }
    get phone() {
        return this.myAccountForm.get('phone');
    }
    get email() {
        return this.myAccountForm.get('email');
    }
}
