import { Injectable } from '@angular/core';
import type { AffiliateDTO, NewsDto, SpecialDealDTO } from '@modeso/types__tsd-lib-products-be';
import {
    DealOfTheDayType,
    Purpose,
    VisualTypes,
    type PublishedDealOfTheDayDto,
} from '@modeso/types__tsd-lib-products-be';
import type { DealOfTheDay } from '../../../../domain/models/deal-of-the-day.interface';
import type { Language } from '../../../../domain/models/types/language.type';
import { convertHexColorAndOpacityToRgba } from '../../../../domain/utils/color.util';
import isCorrectResolution from '../../../../domain/utils/visuals.utils';
import { CampaignMapper } from './campaigns.mapper';

const CAMPAIGN_LIST_ITEM_INDEX = 0;

@Injectable({ providedIn: 'root' })
export class DealOfTheDayMapper {
    constructor(private readonly campaignMapper: CampaignMapper) {}

    public mapPublishedDealsOfTheDayDtoToDealsOfTheDay(
        publishedDealsOfTheDayDto: PublishedDealOfTheDayDto[],
        language: Language,
    ): DealOfTheDay[] {
        const dealsOfTheDay: DealOfTheDay[] = [];

        for (const publishedDealOfTheDayDto of publishedDealsOfTheDayDto) {
            const dealOfTheDay = this.mapPublishedDealOfTheDayDtoToDealOfTheDay(publishedDealOfTheDayDto, language);
            dealsOfTheDay.push(dealOfTheDay);
        }

        return dealsOfTheDay;
    }

    private mapPublishedDealOfTheDayDtoToDealOfTheDay(
        publishedDealOfTheDayDto: PublishedDealOfTheDayDto,
        language: Language,
    ): DealOfTheDay {
        switch (publishedDealOfTheDayDto.type) {
            case DealOfTheDayType.CAMPAIGN:
                return this.prepareCampaignDealOfTheDay(publishedDealOfTheDayDto, language);
            case DealOfTheDayType.DISCOUNT_OFFER:
                return this.prepareDiscountOfferDealOfTheDay(publishedDealOfTheDayDto, language);
            case DealOfTheDayType.NEWS:
                return this.prepareNewsDealOfTheDay(publishedDealOfTheDayDto, language);
            default:
                throw new Error(`The type of the deal of the day is not supported`);
        }
    }

    private prepareCampaignDealOfTheDay(
        publishedDealOfTheDayDto: PublishedDealOfTheDayDto,
        language: Language,
    ): DealOfTheDay {
        const campaign = publishedDealOfTheDayDto.deal as SpecialDealDTO;
        const campaignListItems = this.campaignMapper.mapCampaignsDtoToCampaignListItems([campaign], language);

        const imageInformation = publishedDealOfTheDayDto.visual;

        if (imageInformation.type !== VisualTypes.IMAGE && imageInformation.type !== VisualTypes.NO_BG_IMAGE) {
            throw new Error('The image must either of type Image or type Image with no background');
        }
        return {
            ...campaignListItems[CAMPAIGN_LIST_ITEM_INDEX],
            imageUrl: imageInformation.url, // Overwrite the imageUrl of the campaign mapping
            imageType: imageInformation.type,
            title: publishedDealOfTheDayDto.title,
            type: publishedDealOfTheDayDto.type,
            displayTitle: publishedDealOfTheDayDto.displayTitleInShop,
            hideAvailabilityBar: (publishedDealOfTheDayDto.deal as SpecialDealDTO).hideAvailabilityBar,
        };
    }

    private prepareDiscountOfferDealOfTheDay(
        publishedDealOfTheDayDto: PublishedDealOfTheDayDto,
        language: Language,
    ): DealOfTheDay {
        const discountOffer = publishedDealOfTheDayDto.deal as AffiliateDTO;
        const logo = discountOffer.visuals.find(
            (visual) => visual.purpose === Purpose.THUMBNAIL && isCorrectResolution(visual),
        );

        if (logo == null) {
            throw new Error('Logo is not exist');
        }

        const description = discountOffer.text.find((element) => element.purpose === Purpose.DETAILS);

        if (description == null) {
            throw new Error('Description is not exist');
        }

        const imageInformation = publishedDealOfTheDayDto.visual;

        if (imageInformation.type !== VisualTypes.IMAGE && imageInformation.type !== VisualTypes.NO_BG_IMAGE) {
            throw new Error('The image must either of type Image or type Image with no background');
        }

        return {
            id: discountOffer.affiliateId,
            imageUrl: imageInformation.url,
            imageType: imageInformation.type,
            logoUrl: logo.url,
            text: description[language],
            circularBubbleRgbaColor: convertHexColorAndOpacityToRgba(discountOffer.color, discountOffer.blur),
            title: publishedDealOfTheDayDto.title,
            type: publishedDealOfTheDayDto.type,
            displayTitle: publishedDealOfTheDayDto.displayTitleInShop,
            discountOfferBrandName: discountOffer.brand,
        };
    }

    private prepareNewsDealOfTheDay(
        publishedDealOfTheDayDto: PublishedDealOfTheDayDto,
        language: Language,
    ): DealOfTheDay {
        const news = publishedDealOfTheDayDto.deal as NewsDto;
        const imageInformation = publishedDealOfTheDayDto.visual;

        if (imageInformation.type !== VisualTypes.IMAGE && imageInformation.type !== VisualTypes.NO_BG_IMAGE) {
            throw new Error('The image must either of type Image or type Image with no background');
        }
        return {
            imageUrl: imageInformation.url,
            imageType: imageInformation.type,
            text: news.text[language],
            redirectLink: news.websiteAddress,
            title: publishedDealOfTheDayDto.title,
            type: publishedDealOfTheDayDto.type,
            displayTitle: publishedDealOfTheDayDto.displayTitleInShop,
        };
    }
}
