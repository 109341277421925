export const replaceSpacesWithDashes = (input: string | undefined): string => {
    if (!input) {
        return '';
    }

    if (!input.includes(' ')) {
        console.log('NoSpace');
        return input;
    }
    const trimmedInput = input.trim();
    const result = trimmedInput.replace(/\s+/g, '-');

    return result;
};
